<template>
  <div>
    <!--  we have many different types of rooms ... please click proper tab -->
    <hr style="margin:0px">
      <div v-for="(each,index) in content" :key="index">
        <div v-if="each.title !==''" class="mylocaltitlestyle">
          {{each.title}}
        </div>

        <div class="my_ul_style">
          <ul>
            <div v-for="(mine, index2) in each.des" :key="index2">
              <li>{{mine}}</li>
            </div>
          </ul>          
        </div>          

      </div>
    <hr style="margin-top:0px">
  </div>
</template>

<script>
  export default {
    name: 'TabContent',
    props: [ 'content', ], 
  }
</script>
<style scoped>
.mylocaltitlestyle {
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;    
}

.mylocaldesstyle {
  font-size: 13px;
}

.my_ul_style {
  padding: 0px;
  margin-left: -12px;
  font-size: 12px;
}
</style>